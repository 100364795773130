"use strict";

import Base from './libs/base';
import * as app from './libs/app';
import * as AOS from 'aos';

const 	base   = new Base(),
		common = base.common;

window.onload = () => {
	//共通変数
	const 	detail = common.doc.getElementById('js-detailBody'),

			//初期対応
			init      = () => {
				base.setTouchDeviceClass();
			},

			//アクションイベント
			actionEvent = function(){
				//メニューボタン
				app.menuBtnAciton();

				//詳細ページで実行
				if (detail) {
					//divでtableを囲う
					app.setSurroundTable();

					//目次開閉と作成
					app.setPagenaviTable();
				}

				//AOS
				AOS.init({
					disable : 'mobile',
				});

				//ページTOPボタン
				app.ankerClickPageMove();
			}

	init();
	actionEvent();
};

"use strict";
/* ---------------------------------------------------
	関数用
----------------------------------------------------- */
export default class Base {
	//共通定数
	common = {
		doc       : document,
		html      : document.querySelectorAll('html')[0],
		body      : document.querySelectorAll('body')[0],
		container : document.getElementById('container'),
		header    : document.getElementById('header'),
		g_navi    : document.getElementById('gnavi'),
		main      : document.getElementById('main'),
		conts     : document.getElementById('conts'),
		footer    : document.getElementById('footer'),
		url       : location.pathname,
		ua        : navigator.userAgent
	};

	//デバイスを判定する
	getDevice() {
		const cua = this.common.ua;

		if(cua.indexOf('iPhone') > 0 || cua.indexOf('iPod') > 0 || cua.indexOf('Android') > 0 && cua.indexOf('Mobile') > 0){
			return 'sp';
		}else if(cua.indexOf('iPad') > 0 || cua.indexOf('Android') > 0){
			return 'tab';
		}else{
			return 'other';
		}
	}

	////タッチデバイスか判定しclassをつける
	setTouchDeviceClass() {
		const cthml = this.common.html;

		//デバイスチェック
		if (this.getDevice() === 'other') {
			cthml.classList.add('is-notTouchDevice');
		} else {
			cthml.classList.add('is-touchDevice');
		}
	}

	//アニメーション（ease-out）
	animationEaseOut(p) {
		return p * (2 - p);
	}
}

"use strict";
/* ---------------------------------------------------
	共有関数
 ----------------------------------------------------- */
import Base from './base';

//共通変数
const 	base   = new Base(),
		common = base.common;

/* ---------------------------------------------------
	関数用
----------------------------------------------------- */
//メニューボタン
export function menuBtnAciton() {
	const 	menuBtn   = common.doc.getElementById('js-menu__btn'),
			menuNavi  = common.doc.getElementById('js-menu_navi'),
			menuAfter = common.doc.getElementById('js-navi_after'),
			openClassName: string = 'is-menu_open',

			//閉じる動作
			closeAciton   = () => {
				common.container.classList.remove(openClassName);
				common.container.style.top = '';

				scrollTo(0, firstScolTop);
			};

	let firstScolTop: number = 0; //スクロール値保存

	//メニューボタンによるクリックイベント
	menuBtn.onclick = (e) => {
		e.preventDefault();

		const 	scrlTop = window.pageYOffset,
				_self   = this;

		if (common.container.classList.contains(openClassName)) {
			closeAciton();
		} else {
			common.container.classList.add(openClassName);
			common.container.style.top = '-' + String(scrlTop);

			firstScolTop = scrlTop;
		}
	};

	//メニューが開いた状態のときカバーをクリックした際のアクション
	menuAfter.onclick = (e) => {
		e.preventDefault();

		closeAciton();
	};
};

//table要素を囲む
export function setSurroundTable() {
	const 	body  = common.doc.getElementById('js-detailBody'),
			child = body.children;

	let tables = [];

	//子要素からtableタグだけ取得
	for (const num in child) {
		if(child[num].tagName === 'TABLE') {
			tables.push(child[num]);
		}
	}


	//divで囲む
	for (let i = 0; i < tables.length; i++) {
		tables[i].outerHTML = '<div class="scroll">' + tables[i].outerHTML + '</div>';
	}
};

//目次の作成
export function setPagenaviTable() {
	const 	body      = common.doc.getElementById('js-detailBody'),
			child     = body.children,
			table     = common.doc.getElementById('js-pagenaviTable'),
			pagenavi  = common.doc.getElementById('js-pagenavi'),
			tableTtl  = common.doc.getElementById('js-tableTtl'),
			openClass: string = 'is-open',

			//リストアンカー作成
			setListAnc   = (id, ttl) => {
				const 	text = ttl.innerText,
						list = common.doc.createElement('li'),
						anc  = common.doc.createElement('a');

				if (text === '感想') {
					return false;
				}

				//idの設定
				ttl.setAttribute('id', id);

				//aタグの設定
				anc.textContent = text;
				anc.setAttribute('href', '#' + id);

				//classの設定
				list.classList.add('p-detail__pagenavi--list');

				//liタグにaタグを追加
				list.appendChild(anc);

				return list;
			},

			//h3の目次作成
			setUnderList = (id, data) => {
				const 	level2 = common.doc.createElement('ol'),
						idData = id + '-';

				//class付与
				level2.classList.add('p-detail__pagenavi--in');
				level2.classList.add('is-level2');

				for (let i = 0; i < data.length; i++) {
					const 	ttl  = data[i],
							num  = i + 1,
							id   = idData + num,
							list = setListAnc(id, ttl);

					//目次に追加
					if (list) {
						level2.appendChild(list);
					}
				}

				return level2;
			};

	//変数
	let h2Num: number = 0,
		h2List        = [],
		h3Num: number = 0,
		h3List        = {},
		h3Child       = [];

	//子要素からh2・h3タグだけ取得
	for (const num in child) {

		//h2リスト作成
		if(child[num].tagName === 'H2') {
			h2List.push(child[num]);
			h2Num++;
			h3Child = [];
		}

		//h3リストの作成
		if(child[num].tagName === 'H3') {
			h3Child.push(child[num]);

			h3List[h2Num] = h3Child;
		}
	}

	//h2が無かった場合は非表示
	if (h2List.length === 0) {
		table.style.display = 'none';

		return false;
	}

	//リストを作成
	for (let i = 0; i < h2List.length; i++) {
		const 	ttl  = h2List[i],
				num  = i + 1,
				id   = 'headline' + num,
				list = setListAnc(id, ttl);

		//h3の配列があれば
		if (h3List[num] && list) {
			list.appendChild(setUnderList(id, h3List[num]));
		}

		//目次に追加
		if(list) {
			pagenavi.appendChild(list);
		}
	}

	//SP時のクリックイベント
	tableTtl.onclick = (e) => {
		e.preventDefault();

		if (tableTtl.classList.contains(openClass)) {
			tableTtl.classList.remove(openClass);
		} else {
			tableTtl.classList.add(openClass);
		}
	};
};

//ページ内移動
export function ankerClickPageMove() {
	//定数
	const 	anker    = document.querySelectorAll('a[href^="#"]:not([target="_blank"])'),
			ankerNum = anker.length,
			max      = 50,
			interval = 10,

			//位置取得
			getTargetTop = (elm) => {
				const 	href   = elm.getAttribute('href'),
						target = document.querySelector(href);

				if(target === null) {
					return null;
				}

				const 	info         = target.getBoundingClientRect(),
						posY: number = info.top + window.pageYOffset;

				return posY;
			},

			//位置情報へ移動
			setTargetMove = (elm) => {
				//変数
				let progress: number = 0,
					goal: number     = 0,
					action           = null;

				//定数
				const 	start = window.pageYOffset,     //スタート位置
						move  = () => {
							let pos: number = getTargetTop(elm), //最終位置
								diff        = pos - start,
								upOrDown    = diff <= 0;

							if(pos === null) return false;

							progress++;
							goal = start + (diff * base.animationEaseOut(progress / max));

							window.scrollTo(0, goal);

							// 目的位置に到達したらSTOP
							if ((upOrDown && pos >= goal) || (!upOrDown && goal >= pos)) {
								clearInterval(action);
							}
						};

				action = setInterval(move, interval);
			};

	//クリックイベントをセットする
	function setClickEvent() {
		for(let i = 0; i < ankerNum; i++) {
			const _self = anker[i];

			_self.addEventListener('click', function(e) {
				e.preventDefault();
				setTargetMove(this);
			});
		}
	};

	setClickEvent();
};
